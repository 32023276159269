<template>
  <div style="width: 100%">

    <div class="back-img">
      <div style="font-size: 50px; color: #fff">CALL FOR PAPERS</div>
    </div>

    <div class="text-region">
      <el-row>
        <el-col :md="12" :sm="24">
          <div class="text-box">
            The IEEE Sustainable Power and Energy Conference (iSPEC) is co-sponsored by the CSEE (Chinese Society for Electrical Engineering) and IEEE PES. It takes place every winter in China since 2019, mirroring the IEEE PES General Meeting in North America every summer. The iSPEC2021 is going to be held in December 23-25 in Nanjing, with the theme of “Energy Transition for Carbon Neutrality.”
          </div>
        </el-col>

        <el-col :md="12" :sm="24">
          <div class="text-box">
            The iSPEC2021 will be a hybrid event, offering worldwide perspective and participation. It provides the opportunity to hear new research, ideas and initiatives; and interact with key decision leaders in the power and energy field. Join iSPEC2021 and you will have the chance to network with a wide range of delegates from the utility, product, consultancy, service and business sectors of the power and energy industry.
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="submit-img">
      <img :src="require('assets/images/callfor/submit.png')" alt="Submit Now" @click="submitNow">
    </div>

    <div class="submit-zone">
      <div class="submit-text">
        {{"All accepted papers will be included IN IEEE XPLORE and indexed by EI Compendex.".toUpperCase()}}
      </div>
      <div class="important-dates">
        <img :src="require('assets/images/callfor/dates.png')" alt="">
        <div> Full Paper Submission: August 20, 2021</div>
        <div>Notification of Acceptance: September 30, 2021</div>
        <div>Final Paper Submission: October 20, 2021</div>
      </div>
    </div>

    <div class="contribution-area">

      <div class="digest">
        <div>
          The conference organizing committee invites contributions in all areas related to power and energy technologies, including (but not limited to) the following topics:
        </div>

      </div>

      <el-row class="area-card">
        <el-col v-for="area in areas" :md="12" :sm="24">
          <div class="area-title">{{area.title.toUpperCase()}}</div>
          <div v-for="item in area.items" class="area-item">
            {{item}}
          </div>
        </el-col>
      </el-row>

      <div class="area-notes">
        <div>Downloads :
          <a v-for="quicklink in quickLinkData" :href="quicklink.link">
                {{quicklink.text}}
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
// comment
  name: "CallForPaper",
  methods:{
    submitNow(){
      window.location.href = "http://action.csee.org.cn:8080/ICEE/a/login?redLoginUrl=/a/paper/goPaperindex?activityId=243"      
    }
  },
  data(){
    return{
      areas:[
        {
          title:'1. New Energy Based Modern Power System',
          items:[
            '•  Facilitating high penetration of renewables',
            '•  Integrating multiple energy systems',
            '•  Energy storage and its application',
            '•  System resiliency, economics and stability',
            '•  Energy market and ancillary services'
          ]
        },
        {
          title:'2. Energy Efficiency & Low Carbon',
          items:[
            '• Carbon peak and carbon neutrality',
            '• Alternative fuel and energy source',
            '• CO2 capture, conversion and utilization',
            '• Efficiency improvement and CO2 reduction',
            '• Carbon footprint, audit and transaction',
            '• Low Carbon policy and planning'
          ]
        },
        {
          title:'3. Internet + Intelligent Energy Consumption',
          items:[
            '• Potentiality of flexible resources',
            '• Identification of customer behavior',
            '• DER management and demand response',
            '• Transaction of power consumption right',
            '• Energy substitution and management',
          ]
        },
        {
          title:'4. Electronic Power Grid Systems',
          items:[
            '• Modeling and simulations of electronic power grids',
            '• Stability and reliability of electronic power grid systems',
            '• Power generation/consumption equipment',
            '• FACTS, HVDC and smart Transformers',
          ]
        },
        {
          title:'5. Transportation Electrification',
          items:[
            '• Electric machines and motor drives',
            '• Smart grids interaction with automotive',
            '• Design, management and optimization for powertrain',
            '• Electric vehicle system architectures and control',
            '• Electric aircraft and marine propulsion system',
            '• Onboard and wireless battery chargers',
          ]
        },
        {
          title:'6. Emerging Technology Applications',
          items:[
            '• Machine learning and big data analysis',
            '• Blockchain for distributed application and transaction',
            '• 5G network and communication',
            '• Cloud computing and edge computing',
            '• Internet of Things (IoT) integrated power systems',
            '• Cyber Security Technology',
          ]
        },
      ],
      quickLinkData:[
        {
          text:'Full Paper Temple',
          link: process.env.BASE_URL+ 'lunwen-mb.doc',
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.back-img{
  background-image: url("../assets/images/callfor/bgc1.png");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.text-region{
  margin: 50px 150px;
}

.text-box{
  margin: 10px;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  font-size: 16px;
}

.submit-img{
  display: flex;
  justify-content: center;
  margin: 50px 50px;
}

.submit-zone{
  background-image: url("../assets/images/callfor/bgc2.png");
  height: 410px;
  border-top: 1px solid white;

  &>*{
    margin: 50px ;
  }

  .submit-text{
    text-align: center;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    font-size: 20px;
  }

  .important-dates{
    text-align: center;
    font-size: 24px;
    font-family: FZYDZHJW;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 48px;

    img{
      display: inline-block;
      margin: 10px 0;
    }
  }
}

.contribution-area{

  .digest{
    font-size: 20px;
    font-family: PingFang;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    text-align: center;
    margin: 30px 0;
  }

  .area-card{
    margin: 10px 100px;
    .area-title{
      font-size: 20px;
      font-family: FZZCHJW;
      font-weight: normal;
      line-height: 24px;
      margin: 10px 0;
    }

    .area-item{
      font-size: 16px;
      font-family: PingFang;
      font-weight: 400;
      line-height: 24px;
      margin: 5px 20px;
    }

    &>*{
      padding: 10px 10px 10px 10px;
      height: 250px;
      background: #EEEEEE;
      color: #666666;


      &:nth-child(2),&:nth-child(3),&:nth-child(6){
        background: #11A3CA;
        color: #fff;
      }
    }
  }

}
.area-notes{
   margin-bottom: 30px;
    &>div{
      font-size: 20px;
      color: #000;
      font-family: Microsoft YaHei;
      margin: 5px 100px;
      line-height: 24px;

      a{
        text-decoration: none;
        color: #11A3CA;
      }
    }

}

</style>
